// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: Ubuntu, Helvetica, sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6610f2;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

$primary: #fed136;
$secondary: #6c757d;
$success: #28a745;
$info: #17a2b8;
$warning: #fed136;
$danger: #dc3545;

$light: #f8f9fa;
$dark: #343a40;
