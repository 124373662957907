.carousel-item {
    height: 100vh;
    background: no-repeat center center scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.carousel-caption {
    left: 0;
    right: 0;
    bottom: 0;
    background: rgb(0, 0, 0, 0.33);
}

/* Height for devices larger than 576px */
@media (min-width: 992px) {
    #home-carouse {
        margin-top: -58.59px;
    }
}
