.navbar {
    font-size: 1.2rem;
    font-weight: 600;
    background: rgb(0, 0, 0, .33);
}

// On Shrink
.scrolled {
    .navbar {
        background: rgb(0, 0, 0, .7);
        background: linear-gradient(35deg, rgb(0, 0, 0, .7) 10%, rgb(0, 0, 0, .7) 90%);
        background: -webkit-linear-gradient(55deg, rgb(0, 0, 0, .7) 10%, rgb(0, 0, 0, .7) 90%);
    }
}
