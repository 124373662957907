// Fonts
// @import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Ubuntu');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Font Awesome (Free)
@import '~@fortawesome/fontawesome-free/css/all.css';

// Normalize.css
@import '~normalize.css/normalize.css';

@import "~izitoast/dist/css/iziToast.min.css";

@import 'navbar';

@import 'footer';

@import 'carousel';

.table-hover-cell {
    td:hover {
        color: $table-hover-color;
        background-color: $table-hover-bg;
    }
}

section:not(:first-child) {
    padding: 6rem 0;
}

.section-heading {
    font-size: 2.5rem;
    margin-top: 0;
    margin-bottom: 1rem;
}

.section-subheading {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 4rem;
}
